import React, { useState } from 'react';
import './Locking.scss';
import mercurxMiniIcon from '../../assets/img/logo/mercurx-logo-mini.png';
import { Button, Card, Col, Container, Dropdown, Form, InputGroup, Row } from 'react-bootstrap';
import { lockingMultiplierInfo } from '../../helpers/lockingMultiplierInfo';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import { mainColors } from '../../helpers/colors';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { sortKeys, sortTypes } from './lockingConstants';
import { connect } from 'react-redux';
import {
  getLockingStatisticsRequestAction,
  getLockingHistoryRequestAction,
  postLockingRequestAction,
  sortLockingHistorySortData,
  sortingLockingHistoryAction,
} from '../../store/locking/lockingActions';
// eslint-disable-next-line max-len
import { checkAllConditionForStake } from '../../helpers/verificationHelper';
// eslint-disable-next-line max-len
import MercurXLockingHistoryTable from '../../components/MercurXLockingHistoryTable/MercurXLockingHistoryTable';
import Subscribe from '../../components/MercurXSubscribe/Subscribe';
import wallet from '../../helpers/wallet';
import { useEffect } from 'react';
import { logoutRequestAction } from '../../store/account/userActions';
import {
  walletAccountDetailModalAction,
  WalletConnectModalAction,
} from '../../store/wallet/walletActions';
import Swal from 'sweetalert2';
import { setLoadingAction, setLoadingFullAction } from '../../store/loading/loadingActions';
import Web3 from 'web3';
import { transactionRequest } from '../../store/transaction/transactionActions';
import { ethers } from 'ethers';
import { abiRequestAction } from '../../store/abi/abiActions';

function Locking({ ...props }) {
  const {
    getLockingHistoryRequest,
    getLockingStatisticsRequest,
    postLockingRequest,
    putLockingRequest,
    lockingHistorySortData,
    lockingStatisticsData,
    setSortData,
    sortingLockingHistory,
    balance_,
    accounts,
    setLoading,
    setLoadingFull,
    abiHistory,
    abiHistoryRequest,
    isLoading,
    user,
    tierData,
    project,
    transactionRequest,
  } = props;
  function calculateFormat(x) {

    const formatBalance = (Math.floor((Number(x) / 10000) * 100) / 100).toString().split('.');

    const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
    const secondValueFormatBalance = formatBalance[1] || "0";
    return firstValueFormatBalance + ',' + secondValueFormatBalance;
  }
  function calculateNewBalance() {

    const formatBalance = 
    (Math.floor((Number(balance_) / 10000) * 100) / 100).toString().split('.');

    const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
    const secondValueFormatBalance = formatBalance[1] || "0";
    return firstValueFormatBalance + ',' + secondValueFormatBalance;
  }
  const data = [
    {
      name: 'Locked for 1 Month',
      value: lockingStatisticsData?.lock_one_month/10000,
      color: mainColors['tertiary-light'],
    },
    {
      name: 'Locked for 3 Month',
      value: lockingStatisticsData?.lock_three_month/ 10000,
      color: mainColors.primary,
    },
    {
      name: 'Locked for 6 Month',
      value: lockingStatisticsData?.lock_six_month/ 10000,
      color: mainColors.tertiary,
    },
    {
      name: 'Locked for 12 Month',
      value: lockingStatisticsData?.lock_twelve_month/ 10000,
      color: mainColors['primary-light'],
    },
  ];
  const [selectedMultiplier, setSelectedMultiplier] = useState(lockingMultiplierInfo[0]);
  const [userWalletBalance, setUserWalletBalance] = useState(1000.86);
  const [valueToBeMultiplied, setValueToBoMultiplied] = useState(0);
  const [pieActiveIndex, setPieActiveIndex] = useState(0);
  const [txs, setTxs] = useState([]);
  const { utils } = require('ethers');

  useEffect(() => {
    handleAbi();
  }, []);

  // Bu kodun bulunduğu bileşen içerisinde
  function get_lock_history() {
    const address = utils.getAddress(accounts?.[0]);
    const payload = {
      id: address,
    };
    const payload1 = {
      wallet: address,
    };
    getLockingStatisticsRequest(payload1);
    getLockingHistoryRequest(payload);
  }
  useEffect(() => {
    if (accounts?.[0]) {
      wallet.getMyBalance('0x0f1C0c3435e866ec002BbfA1C30cb867453b8920');
      get_lock_history();
    }
  }, [accounts]);

  const handleAbi = () => {
    abiHistoryRequest();
  };

  function isActiveMultiplier(multiplierId) {
    if (selectedMultiplier.id === multiplierId) {
      return true;
    }
  }
  function convertStartTime(currentDate) {
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;
  }

  function convertEndTime(currentDate, months) {
    const newDate = new Date(currentDate);
    newDate.setMinutes(newDate.getMinutes() + months);
    const year = newDate.getFullYear();
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const day = newDate.getDate().toString().padStart(2, '0');
    const hours = newDate.getHours().toString().padStart(2, '0');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');
    const seconds = newDate.getSeconds().toString().padStart(2, '0');
    const milliseconds = newDate.getMilliseconds().toString().padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;
  }
  function calculateBalance() {
    const formatBalance = (Math.floor((Number(balance_) / 10000) * 100) / 100).toString();
    const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
    const secondValueFormatBalance = formatBalance[1] || '0';

    return formatBalance;
  }

  function onPieEnter(e, index) {
    setPieActiveIndex(index);
  }
  function onChangeLockingMerx(e) {
    const inputValue = e.target.value.replace(',', '.');
    if (/^\d*\.?\d{0,2}$/.test(inputValue) && Number(inputValue) <= Number(calculateBalance())) {
      setValueToBoMultiplied(inputValue);
    }
  }

  const lockToken = async (e) => {
    // 0x7FFB0F173B9339134122FDBF22353363Cbe11fe3 dynamic swap contract address
    // TODO : should be added to database
    // postLocking();
    e.preventDefault();
    setTxs([]);
    setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
    const data = valueToBeMultiplied;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    const signerAddress = await signer.getAddress();
    const web3 = new Web3(window.ethereum);
    await wallet.controlAndSwitchOrAddNetwork();
    await window.ethereum.enable();
    const mercurx_token = new web3.eth.Contract(
      abiHistory?.[0]?.['MERX_abi'],
      '0x0f1C0c3435e866ec002BbfA1C30cb867453b8920',
    );
    const mercurx_lock = new web3.eth.Contract(
      abiHistory?.[0]?.['MERX_lock_abi_minutes'],
      '0x58c12fe3Fa9e3EF857A3B9E32927F7Fca9C75Abb',
    );
    const etherMiktari = valueToBeMultiplied;
    const project_id = 1;
    const project_name = "Mercurx";
    const action = 'staking';
    const token_count = valueToBeMultiplied;
    const token_address = '0x0f1C0c3435e866ec002BbfA1C30cb867453b8920';
    const transaction_time = new Date();
    const user_public_address = signerAddress;
    try {
      const approve_transaction = await mercurx_token.methods
        .approve(
          '0x58c12fe3Fa9e3EF857A3B9E32927F7Fca9C75Abb',
          web3.utils.toWei(String(Number(etherMiktari) * 10000), 'wei'),
        )
        .send({
          from: signerAddress,
          to: '0x58c12fe3Fa9e3EF857A3B9E32927F7Fca9C75Abb',
          data: web3.eth.abi.encodeFunctionSignature('whitdrawETH()'),
        });
      const transaction = await mercurx_lock.methods
        .lockTokens(
          web3.utils.toWei(String(Number(etherMiktari) * 10000), 'wei'), selectedMultiplier.index)
        .send({
          from: signerAddress,
          to: '0x58c12fe3Fa9e3EF857A3B9E32927F7Fca9C75Abb',
          data: [web3.eth.abi.encodeFunctionSignature('whitdrawETH()'), 0],
        });
      wallet.getMyBalance('0x0f1C0c3435e866ec002BbfA1C30cb867453b8920');
      const transaction_status = transaction.status;
      const transaction_hash = transaction.transactionHash;

      const payload2 = {
        project_id,
        project_name,
        token_count,
        user_public_address,
        token_address,
        transaction_hash,
        transaction_time,
        transaction_status,
        action,
      };
      transactionRequest(payload2);
      postLocking(transaction_hash);

      Swal.fire({
        icon: 'success',
        iconColor: mainColors.primary,
        text: 'Transaction succeed',
        confirmButtonColor: mainColors.primary,
        html:
          '<a href=https://testnet.bscscan.com/tx/' +
          transaction.transactionHash +
          " target='_blank'> Check Detail Transaction !</a>",
      });
      setLoadingFull({ isLoading: false });
      get_lock_history();
    } catch (err) {
      if (err?.receipt?.transactionHash) {
        Swal.fire({
          icon: 'error',
          iconColor: '#E40039',
          title: 'Transaction is Failed',
          confirmButtonColor: '#E40039',
          // eslint-disable-next-line max-len, no-template-curly-in-string
          html:
            '<a href=https://testnet.bscscan.com/tx/' +
            err.receipt.transactionHash +
            " target='_blank'> Check Detail Transaction !</a>",
        });
        setLoadingFull({ isLoading: false });
        const transaction_status = false;
        const transaction_hash = err.receipt.transactionHash;

        const payload2 = {
          project_id,
          project_name,
          token_count,
          user_public_address,
          token_address,
          transaction_hash,
          transaction_time,
          transaction_status,
          action,
        };
        transactionRequest(payload2);
      } else {
        Swal.fire({
          icon: 'warning',
          iconColor: '#E40039',
          confirmButtonColor: '#E40039',
          text: err.message,
        });
        setLoadingFull({ isLoading: false });
      }
    }
  };

  function postLocking(transaction_hash) {
    const currentDate = new Date();
    const startDate = convertStartTime(currentDate);
    const endDate = convertEndTime(currentDate, selectedMultiplier.months);
    const address = utils.getAddress(accounts?.[0]);
    const payload = {
      symbol: 'merx',
      wallet: address,
      tx_hash: transaction_hash,
      month: selectedMultiplier.months,
      value: valueToBeMultiplied*10000,
      reward: (valueToBeMultiplied * selectedMultiplier.multiplier).toFixed(4).toString(),
      start_lock_time: startDate,
      finish_lock_time: endDate,
      claim_status: true,
    };
    console.log(payload)
    postLockingRequest(payload);
  }

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
      color,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={color}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={color}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={color}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={color} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={color} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${calculateFormat(value*10000)}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  function changeSortType(sortType) {
    const newLockingHistorySortData = { ...lockingHistorySortData, sortType };
    setSortData(newLockingHistorySortData);
    sortingLockingHistory();
  }

  function changeSortKey(sortKey) {
    const newLockingHistorySortData = { ...lockingHistorySortData, sortKey };
    setSortData(newLockingHistorySortData);
    sortingLockingHistory();
  }
  let chartValues = 0;
  useEffect(() => {
    chartValues = data.reduce((total, d) => total + parseInt(d.value), 0);
    // console.log(chartValues);
  }, [data]);

  return (
    <Container>
      <Row className="mb-5">
        {lockingMultiplierInfo.map((item, index) => {
          return (
            <Col key={index} sm={12} md={3}>
              <Card
                className={`${isActiveMultiplier(item.id) ? 'border-primary' : 'border-0'
                  } py-2 px-3 shadow cursor-pointer`}
                onClick={() => setSelectedMultiplier(lockingMultiplierInfo[index])}
              >
                <img
                  className="img-fluid mx-auto mb-2"
                  alt="mercurx-mini-icon"
                  src={mercurxMiniIcon}
                  width={50}
                />
                <div className="text-fs-head-xs mx-auto">{item.title}</div>
                {item.features.map((feature, fIndex) => {
                  return (
                    <div
                      key={fIndex}
                      className={`${feature.highlight && 'text-primary'} text-fs-body-md`}
                    >
                      • {feature.content}
                    </div>
                  );
                })}
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row className="px-3 mb-5">
        <div className="shadow p-3 rounded">
          <Row className="align-items-center mb-4">
            <Col sm="12" md="6">
              <div className="text-fs-head-sm text-primary">MERX TO BE LOCKED</div>
            </Col>
            <Col sm="12" md="6">
              <InputGroup className="border-primary">
                <Form.Control
                  className="border-primary border-end-0"
                  step="any"
                  type="number"
                  value={valueToBeMultiplied}
                  onChange={onChangeLockingMerx}
                  max={Number(calculateBalance())}
                  aria-describedby="merx-desc"
                />
                <InputGroup.Text className="border-primary bg-white text-muted" id="merx-desc">
                  MERX
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Range
              className="px-2"
              step="any"
              max={Number(calculateBalance())}
              defaultValue={0}
              value={valueToBeMultiplied ? valueToBeMultiplied : 0}
              onChange={(e) => {
                const newValue = parseFloat(e.target.value).toFixed(2);
                console.log("Range value:", newValue);
                setValueToBoMultiplied(newValue);
              }}
            />
            <div className="text-end">{calculateNewBalance()} MERX</div>
          </Row>
          <Row>
            {checkAllConditionForStake(user, accounts) ? (
              <Button onClick={lockToken} disabled={valueToBeMultiplied <= 0.99}>
                <span className="ms-1">Lock MERX</span>
              </Button>
            ) : (
              <Button disabled={true}>
                <span className="ms-1">Lock MERX</span>
              </Button>
            )}

            <div className="text-center text-fs-body-sm">
              Minimum amount of tokens you can lock is 1.
            </div>
          </Row>
        </div>
      </Row>
      <div className="text-fs-head-sm mb-2"> Lock MERX Stats</div>
      <Row className="mb-5">
        <Col sm={12} md={4}>
          <Card className="shadow border-0 py-2 px-3">
            <div className="text-fs-head-sm">Total Tokens Locked</div>
            <div className="text-fs-head-sm text-primary">
              {' '}
              {lockingStatisticsData
                ? 
                // (lockingStatisticsData.total_locked_token/10000).toFixed(4)
                (calculateFormat(lockingStatisticsData.total_locked_token) ) 
                : 'Login For Statistics'}
            </div>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card className="shadow border-0 py-2 px-3">
            <div className="text-fs-head-sm">No. of Users That Locked</div>
            <div className="text-fs-head-sm text-primary">
              {' '}
              {lockingStatisticsData
                ? lockingStatisticsData.unique_cuser_count
                : 'Login For Statistics'}
            </div>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card className="shadow border-0 py-2 px-3">
            <div className="text-fs-head-sm">Total MERX Reward</div>
            <div className="text-fs-head-sm text-primary">
              {lockingStatisticsData
                ?
                (calculateFormat(lockingStatisticsData.total_reward) ) 
                // (lockingStatisticsData.total_reward/10000).toFixed(4)
                : 'Login For Statistics'}
            </div>
          </Card>
        </Col>
      </Row>
      {!lockingStatisticsData ? (
        <div
          className="mt-5 mb-5 d-flex 
        justify-content-center align-items-center text-fs-head-sm text-primary"
        >
          Login For Statistics
        </div>
      ) : (
        (chartValues = 0 ? (
          <>
            <div className="text-fs-head-md text-center"> TOKENS LOCKED</div>
            <div className="mt-5 mb-5 d-flex justify-content-center align-items-center">
              No Tokens Locked
            </div>
          </>
        ) : (
          <div className="mercurx-chart-wrapper mb-5">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Pie
                  activeIndex={pieActiveIndex}
                  activeShape={renderActiveShape}
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={100}
                  outerRadius={120}
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                >
                  {data.map((d, i) => {
                    return <Cell key={'cell-' + i} fill={d.color} />;
                  })}
                </Pie>
                <Legend verticalAlign="bottom" height={36} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        ))
      )}

      <div className="text-fs-head-sm mb-2"> My Lock History</div>
      <Row className="mb-5">
        <Col sm={12} md={4}>
          <Card className="shadow border-0 py-2 px-3">
            <div className="text-fs-head-sm">My Locked MERX</div>
            <div className="text-fs-head-sm text-primary">
              {' '}
              {lockingStatisticsData ? 
              (calculateFormat(lockingStatisticsData.my_total_lock) ) :
               'Login For Statistics'}
              
            </div>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card className="shadow border-0 py-2 px-3">
            <div className="text-fs-head-sm">Sum Of All Locks</div>
            <div className="text-fs-head-sm text-primary">
              {' '}
              {lockingStatisticsData
                ? lockingStatisticsData.total_active_locks
                : 'Login For Statistics'}
            </div>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card className="shadow border-0 py-2 px-3">
            <div className="text-fs-head-sm">My Total Rewards</div>
            <div className="text-fs-head-sm text-primary">
              {lockingStatisticsData
                ?
                (calculateFormat(lockingStatisticsData.my_total_reward) ) 
                // (lockingStatisticsData.my_total_reward/10000 ).toFixed(4)
                : 'Login For Statistics'}
            </div>
          </Card>
        </Col>
      </Row>
      <Row
        id="locking-history-container"
        className="d-flex align-items-center justify-content-between"
      >
        <Col>
          <div id="token-sorting-section" className="d-flex align-items-center py-2">
            <Dropdown className="me-2 sales-table-button">
              <Dropdown.Toggle className="d-flex align-items-center">
                <MercurXIcons name="MdSort" size="18" />
                <span className="ms-1">{sortTypes[lockingHistorySortData.sortType].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortTypes.map((sortType, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortType(index)}
                    >
                      <MercurXIcons name={sortType.icon} size="18" />
                      <span className="ms-2">{sortType.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="sales-table-button">
              <Dropdown.Toggle className="d-flex align-items-center">
                <MercurXIcons name="BiFilterAlt" size="18" />
                <span className="ms-1">{sortKeys[lockingHistorySortData.sortKey].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortKeys.map((sortKey, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortKey(index)}
                    >
                      <span>{sortKey.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        <MercurXLockingHistoryTable />
      </Row>

      <Subscribe />
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    lockingHistoryData: state.lockingReducer.lockingHistoryData,
    lockingStatisticsData: state.lockingReducer.lockingStatisticsData,
    lockingHistorySortData: state.lockingReducer.lockingHistorySortData,
    balance_: state.walletReducer.balance_,
    abiHistory: state.abiReducer.abiHistory,
    isLoading: state.loadingReducer.isLoading,
    tierData: state.tierReducer.tierData,
    accounts: state.walletReducer.accounts,
    user: state.userReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    abiHistoryRequest: (payload) => {
      dispatch(abiRequestAction(payload));
    },
    setLoading: (payload) => {
      dispatch(setLoadingAction(payload));
    },
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
    logoutRequest: (payload) => {
      dispatch(logoutRequestAction(payload));
    },
    walletAccountDetailModalRequest: (payload) => {
      dispatch(walletAccountDetailModalAction(payload));
    },
    WalletConnectModalRequest: (payload) => {
      dispatch(WalletConnectModalAction(payload));
    },
    getLockingHistoryRequest: (payload) => {
      dispatch(getLockingHistoryRequestAction(payload));
    },
    getLockingStatisticsRequest: (payload) => {
      dispatch(getLockingStatisticsRequestAction(payload));
    },
    postLockingRequest: (payload) => {
      dispatch(postLockingRequestAction(payload));
    },
    setSortData: (payload) => {
      dispatch(sortLockingHistorySortData(payload));
    },
    sortingLockingHistory: (payload) => {
      dispatch(sortingLockingHistoryAction(payload));
    },
    transactionRequest: (creds) => {
      dispatch(transactionRequest(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Locking);
