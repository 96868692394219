import { takeEvery, all, call, put } from 'redux-saga/effects';

import * as types from './tierActionTypes';
import * as actions from './tierActions';
// import * as alert from '../alert/alertActions';
import * as endpoints from '../../services/endpoints';

function* getTierSaga(action) {
  try {
    const { data } = yield call(endpoints.getTier, action.payload.id);
    yield put(actions.getTierDataAction(data));
  } catch (e) {
    // yield put(
    //   alert.setAlertAction({
    //     title: 'Error!',
    //     text: 'Something went wrong when calling tier service.',
    //     color: 'danger',
    //     outTimeMS: 6000,
    //   }),
    // );
    yield put(actions.getTierErrorAction(e));
  }
}
//Watcher Sagas
function* watchGetTierHistory() {
  yield takeEvery(types.GET_TIER_REQUEST, getTierSaga);
}

export function* tierSaga() {
  yield all([watchGetTierHistory()]);
}
