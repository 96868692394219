import { takeEvery, all, call, put } from 'redux-saga/effects';

import * as types from './lockingActionTypes';
import * as actions from './lockingActions';
import * as alert from '../alert/alertActions';
import * as endpoints from '../../services/endpoints';

//Worker Sagas

function* getLockingHistorySaga(action) {
  try {
    console.log(action.payload)
    const { id } = action.payload;

    const { data } = yield call(endpoints.getLockingHistory, id);
    yield put(actions.getLockingHistoryDataAction(data));
  } catch (e) {
    // yield put(
      // alert.setAlertAction({
      //   title: 'Error!',
      //   text: 'Something went wrong when calling lock history service.',
      //   color: 'danger',
      //   outTimeMS: 6000,
      // }),
    // );
    yield put(actions.getLockingHistoryErrorAction(e));
  }
}
function* postLockingSaga(action) {
  try {
    const { data } = yield call(endpoints.postLocking, action.payload);
    yield put(actions.postLockingDataAction(data));
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: 'Something went wrong when calling post locking service.',
        color: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.postLockingErrorAction(e));
  }
}
function* putLockingSaga(action) {
  try {
    yield put(actions.putLockingDataAction(null));
    const { id } = action.payload;
    const { data } = yield call(endpoints.putLocking, id);
    yield put(actions.putLockingDataAction(data));
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: 'Something went wrong when calling put locking service.',
        color: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.putLockingErrorAction(e));
  }
}
function* getLockingStatisticsSaga(action) {
  try {
    const { wallet } = action.payload;
    const { data } = yield call(endpoints.getLockingStatistics, wallet);
    yield put(actions.getLockingStatisticsDataAction(data));
  } catch (e) {
    // yield put(
      // alert.setAlertAction({
      //   title: 'Error!',
      //   text: 'Something went wrong when calling lock statistics service.',
      //   color: 'danger',
      //   outTimeMS: 6000,
      // }),
    // );
    yield put(actions.getLockingStatisticsErrorAction(e));
  }
}
//Watcher Sagas
function* watchGetLockingHistory() {
  yield takeEvery(types.GET_LOCKING_STATISTICS_REQUEST, getLockingStatisticsSaga);
  yield takeEvery(types.GET_LOCKING_HISTORY_REQUEST, getLockingHistorySaga);
  yield takeEvery(types.POST_LOCKING_REQUEST, postLockingSaga);
  yield takeEvery(types.PUT_LOCKING_REQUEST, putLockingSaga);
}

export function* lockingSaga() {
  yield all([watchGetLockingHistory()]);
}
