/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, FormLabel, InputGroup, Modal } from 'react-bootstrap';
import MercurXFormControl from '../../components/MercurXFormControl/MercurXFormControl';
import { signUpRequest } from '../../store/account/userActions';
import { NavLink } from 'react-router-dom';
import { mainColors } from '../../helpers/colors';
import './SignUp.scss';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';

function SignUp({ ...props }) {
  const { sign, history } = props;
  const [state, setState] = useState({
    data: {
      username: '',
      password: '',
      email: '',
    },
    errors: {},
    passwordVisibility: false,
    termsAccepted: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [acceptEnabled, setAcceptEnabled] = useState(false);
  const modalBodyRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (modalBodyRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = modalBodyRef.current;
        if (scrollTop + clientHeight >= scrollHeight) {
          setAcceptEnabled(true);
        }
      }
    };

    if (showModal && modalBodyRef.current) {
      modalBodyRef.current.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (modalBodyRef.current) {
        modalBodyRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [showModal]);

  const handleTermsChange = () => {
    setState(prevState => ({
      ...prevState,
      termsAccepted: !prevState.termsAccepted,
    }));
  };

  const handleAccept = () => {
    setShowModal(false);
    setState(prevState => ({
      ...prevState,
      termsAccepted: true,
    }));
  };

  const handleTogglePasswordVisibility = () => {
    setState(prevState => ({
      ...prevState,
      passwordVisibility: !prevState.passwordVisibility,
    }));
  };

  const validatePassword = (password) => {
    const errors = [];

    if (!password || password.length < 8) {
      errors.push({ message: 'Must be at least 8 characters long.', isValid: false });
    } else {
      errors.push({ message: 'Must be at least 8 characters long.', isValid: true });
    }

    if (!/[A-Z]/.test(password)) {
      errors.push({ message: 'Must contain at least one uppercase letter.', isValid: false });
    } else {
      errors.push({ message: 'Must contain at least one uppercase letter.', isValid: true });
    }

    if (!/[a-z]/.test(password)) {
      errors.push({ message: 'Must contain at least one lowercase letter.', isValid: false });
    } else {
      errors.push({ message: 'Must contain at least one lowercase letter.', isValid: true });
    }

    if (!/\d/.test(password)) {
      errors.push({ message: 'Must contain at least one digit.', isValid: false });
    } else {
      errors.push({ message: 'Must contain at least one digit.', isValid: true });
    }

    if (!/[@$!%*?&]/.test(password)) {
      errors.push({ message: 'Must contain at least one special character (@, $, !, %, *, ?, &).', isValid: false });
    } else {
      errors.push({ message: 'Must contain at least one special character (@, $, !, %, *, ?, &).', isValid: true });
    }

    return errors;
  };

  const validate = () => {
    const { data } = state;
    const errors = {};

    if (!data || typeof data !== 'object') {
      return errors;
    }

    if (!data.username) {
      errors.username = 'Username cannot be blank.';
    } else if (data.username.length < 3) {
      errors.username = 'Username must be at least 3 characters long.';
    }

    if (!data.email) {
      errors.email = 'Email cannot be blank.';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(data.email)) {
        errors.email = 'Invalid email format.';
      }
    }

    if (!data.password) {
      errors.password = 'Password cannot be blank.';
    } else {
      const passwordErrors = validatePassword(data.password);
      const invalidPasswordErrors = passwordErrors.filter(error => !error.isValid);

      if (invalidPasswordErrors.length > 0) {
        errors.password = invalidPasswordErrors.map(error => error.message).join(' ');
      }
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { data } = state;
    const errors = validate();

    const payload = {
      data,
      history: history,
    };

    if (Object.keys(errors).length === 0) {
      sign(payload);

      setState((prevState) => ({
        ...prevState,
        errors: {},
        passwordVisibility: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        errors,
      }));
    }
  };

  const handleChange = (e) => {
    setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [e.target.id]: e.target.value,
      },
      errors: {
        ...prevState.errors,
        [e.target.id]: '',
      },
    }));
  };

  const { data, errors, passwordVisibility } = state;

  return (
    <Row className="d-flex justify-content-center align-items-center">
      <Col className="public-signup-layout-col d-flex justify-content-center align-items-center bg-white px-1 py-2">
        <div className="public-signup-layout-image d-md-flex d-none flex-column justify-content-center align-items-center bg-primary px-1 py-2">
          <div className="text-fs-head-lg text-center text-light px-2">
            Welcome to the exciting world of <span>MercurX</span>
          </div>
          <div className="text-fs-body-md text-center text-light px-2">
            Step into the dynamic sphere of MercurX, where each exploration promises fascinating
            discoveries in the world of digital currency and blockchain!
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center aling-items-center px-4 mx-2 public-signup-layout-form">
          <Form onSubmit={handleSubmit}>
            <MercurXFormControl
              label="Your Name"
              type="text"
              id="username"
              value={data?.username}
              handleChange={handleChange}
              error={errors?.username}
              placeholder="Name Surname"
            />
            <Form.Label className={`text-${errors?.username ? 'danger' : 'success'} text-fs-body-sm mercurx-error-label`}>
              {errors?.username && '*' + errors?.username}
            </Form.Label>
            <MercurXFormControl
              label="Email"
              type="text"
              id="email"
              value={data?.email}
              handleChange={handleChange}
              error={errors?.email}
              placeholder="example@gmail.com"
            />
            <Form.Label className={`text-${errors?.email ? 'danger' : 'success'} text-fs-body-sm mercurx-error-label`}>
              {errors?.email && '*' + errors?.email}
            </Form.Label>
            <FormLabel className="text-fs-body-lg text-t-head-color">
              Password
            </FormLabel>
            <InputGroup>
              <Form.Control
                type={passwordVisibility ? 'text' : 'password'}
                name="password"
                id="password"
                placeholder="••••"
                value={data?.password}
                isInvalid={!!errors.password}
                onChange={handleChange}
              />
              <InputGroup.Text id="basic-addon2" className="d-flex justify-content-between align-items-center input-group-text-custom">
                <Col>
                  <div onClick={handleTogglePasswordVisibility}>
                    <MercurXIcons
                      name={passwordVisibility ? 'BsEyeSlash' : 'BsEye'}
                      iconClassName="mercurx-password-icon"
                    />
                  </div>
                </Col>
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </InputGroup>
            {data?.password && validatePassword(data.password).map((error, index) => (
              <Form.Label key={index} className={`text-${error.isValid ? 'success' : 'danger'} text-fs-body-sm mercurx-error-label`}>
                {error.isValid ? '✓ ' : '* '}
                {error.message}
              </Form.Label>
            ))}
            <Form.Group className="mt-4" controlId="termsAndConditions">
              <div className="d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  id="termsAccepted"
                  label="I accept the"
                  checked={state.termsAccepted}
                  onClick={(e) => {
                    e.preventDefault(); 
                    setShowModal(true); 
                  }}
                  style={{ marginBottom: 0 }}
                />
                <span
                  onClick={() => setShowModal(true)}
                  aria-controls="terms-text"
                  aria-expanded={showModal}
                  style={{ color: mainColors.primary, textDecoration: 'underline', cursor: 'pointer', marginLeft: '5px' }}
                >
                  terms and conditions
                </span>
                <span>.</span>
              </div>
            </Form.Group>

            <Button
              className="mb-4 mt-3 bg-mercurx-primary col-sm-12"
              type="submit"
              disabled={!state.termsAccepted}
            >
              Sign up
            </Button>
          </Form>
          <Col className="m-1 d-flex justify-content-center">
            <NavLink
              className="text-fs-body-md text-t-body-color justify-content-center"
              to="/signup"
            >
              Do you have an account?
            </NavLink>

            <NavLink className="text-primary p-1" to="/login">
              Login
            </NavLink>
          </Col>
        </div>
      </Col>

      <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Terms and conditions for Mercurx</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={modalBodyRef} className="custom-modal-body">
          <p className="text-fs-body-md">
            By using the <a href="https://launchpad.mercurx.com/" target="_blank"
              rel="noopener noreferrer">https://launchpad.mercurx.com/ </a>
            website (the "Site"), its subdomains, and any services available within the site
            (collectively the "Services"), you agree to be bound by these Terms of Use as well as our Privacy Policy.
            If you do not agree to the Terms of Use herein, do not use the Site or Services. The Site and Services are
            provided by Elsa Bilişim A.Ş. (also referred to as Company) a company incorporated in Denizli/Turkey.
            Company reserves the right, at its sole discretion, to amend, change, modify, add or remove portions of these
            Terms of Use at any time. It is your responsibility to check these terms periodically for changes.
            Your continued use of the Services following the published updates to the terms will mean that you accept
            and agree to the changes. We offer a wide range of products and services, and additional terms may apply.
            When you use Company’s Services, you will also be subject to the guidelines, terms, and agreements applicable
            to that particular service (the "Service Terms"). If these Terms of Use are inconsistent with the Service Terms,
            the Service Terms will control.
          </p>

          <p className="text-fs-body-md ">
            THIS AGREEMENT CONTAINS AN ARBITRATION PROVISION.
            ALL DISPUTES ARISING UNDER THIS USER AGREEMENT SHALL BE SETTLED IN BINDING ARBITRATION.
            ENTERING INTO THIS AGREEMENT CONSTITUTES A WAIVER OF YOUR RIGHT TO A TRIAL BY JURY AND
            PARTICIPATION IN A CLASS ACTION LAWSUIT.
          </p>
          <p className="text-fs-body-lg ">
            1. Accessing the Services
          </p>
          <p className="text-fs-body-md">
            Company grants you a revocable,
            non-exclusive, non-transferable, limited license to use the Services
            (information or software) on the Site in accordance with these Terms of Use
            and applicable Service Terms. This is the grant of a license, not a transfer of title,
            and under this license, you may not:
          </p>
          <p className="text-fs-body-md">
            Company grants you a revocable,
            non-exclusive, non-transferable, limited license to use the Services
            (information or software) on the Site in accordance with these Terms of Use
            and applicable Service Terms. This is the grant of a license, not a transfer of title,
            and under this license, you may not:
          </p>
          <p className="text-fs-body-md mt-4">
            •	modify or copy the materials; </p>
          <p className="text-fs-body-md mt-2"> •	attempt to decompile or reverse engineer any software contained on the Site; </p>
          <p className="text-fs-body-md mb-2">  •	remove any copyright or other proprietary notations from the materials; or   </p>
          <p className="text-fs-body-md mb-4"> •	transfer the materials to another person or "mirror" the materials on any other server.</p>
          <p className="text-fs-body-md">
            This license shall automatically terminate
            if you violate any of these restrictions and
            may be terminated by Company at any time.
            Upon terminating your viewing of these materials or
            upon the termination of this license, you must destroy
            any downloaded materials in your possession whether in
            electronic or printed format. Otherwise you agree to be
            held responsible for any resulting harm of the Company.
          </p>
          <p className="text-fs-body-md">
            You must keep secret all credentials associated with the Services.
            You are solely responsible for managing and maintaining the security of
            any information relating to such credentials and agree that Company shall
            not be held responsible (and you shall not hold us responsible) for any
            unauthorized access to the Services or any resulting harm you may suffer.
          </p>
          <p className="text-fs-body-md">
            You agree to notify us immediately of any unauthorized access to or use of your user name
            or password or any other breach of security. You also agree to ensure that you exit from your
            account at the end of each session. You should use particular caution when accessing your account
            from a public or shared computer so that others are not able to view or record your password or
            other personal information.
          </p>
          <p className="text-fs-body-md">
            Your access to one or more Services may be contingent upon satisfaction of our onboarding processes,
            which may include verification of your identity and additional information we may request from time-to-time.
            The information we request may include, without limitation, personally identifiable information such as network
            address, name, email, address, telephone number, date of birth, taxpayer identification or social security number,
            official government-issued photo identification, and bank account information or other information we
            may reasonably deem helpful in satisfying our risk management or legal obligations. In providing this
            information to us you represent that it is accurate and agree to update your information promptly,
            but in no event later than 14 days following any change in your information. Failure to provide
            information or update it promptly may result in Services or funds being unavailable to you.
            Company shall not be held responsible (and you shall not hold us responsible) for any resulting harm you
            may suffer due to that situation.
          </p>
          <p className="text-fs-body-md">
            Any and all communications from Company may be provided to you via electronic mail at the address you provided
            when accessing the Services. Company shall not be responsible for any harm you may suffer as a result of your
            failure to receive any notice provided to you in connection with your use of Services so long as such notice
            is provided to such email address. Company may also communicate with you through other methods, including
            via telephone call, instant messaging or chat applications either operated by Company or a third party.
          </p>
          <p className="text-fs-body-md">
            We may close, terminate, enable or disable any or all of the Services,
            or your access to the Services at any time and for any reason.
          </p>
          <p className="text-fs-body-lg ">
            2. Non-Custodial Nature of Company Services
          </p>
          <p className="text-fs-body-md">
            Company does not facilitate or provide brokerage, exchange, payment, escrow, remittance or merchant services.
          </p>
          <p className="text-fs-body-md">
            Launchpad services for the Mercurx ecosystem.
          </p>
          <p className="text-fs-body-lg ">
            3. Disclaimer
          </p>
          <p className="text-fs-body-md">
            PURPOSE, DATA ACCURACY, SYSTEM INTEGRATION, TITLE, NON-INFRINGEMENT AND/OR QUIET ENJOYMENT,
            AND ANY SERVICES PROVIDED BY COMPANY ARE PROVIDED "AS IS" AND "AS AVAILABLE".
            EXCEPT AS EXPRESSLY PROVIDED HEREIN, YOU ACKNOWLEDGE THAT COMPANY MAKES NO WARRANTIES UNDER
            THIS AGREEMENT DIRECTLY FOR THE BENEFIT OF ANY END USER, AND THAT COMPANY’S OBLIGATIONS UNDER
            THIS AGREEMENT ARE FOR THE BENEFIT OF YOU ONLY, AND NOT FOR THE BENEFIT OF ANY OTHER PERSON.
            IN ENTERING INTO THIS AGREEMENT, YOU REPRESENT THAT YOU HAVE NOT RELIED UPON ANY REPRESENTATION OR WARRANTY
            OF COMPANY OR ITS AFFILIATES EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT.
            WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE SERVICES SHALL BE CONTINUOUS,
            UNINTERRUPTED, TIMELY, OR ERROR-FREE.
          </p>
          <p className="text-fs-body-md">
            You understand that we cannot and do not guarantee or warrant that files available for downloading
            from the Site will be free of viruses or other destructive code. You are responsible for
            implementing sufficient procedures and checkpoints to satisfy your particular requirements
            for anti-virus protection and accuracy of data input and output, and for maintaining a means
            external to our site for any reconstruction of any lost data.
          </p>
          <p className="text-fs-body-lg ">
            4. Risk of Digital Assets and Decentralized Networks
          </p>
          <p className="text-fs-body-md">
            Please note that all transactions involving digital assets involve substantial risks.
            You should therefore carefully consider whether using our Services is suitable for you
            in light of your financial condition. In considering whether to hold digital assets,
            you should be aware that the price or value of digital assets can change rapidly, decrease,
            and potentially even fall to zero. Past performance is not an indicator of future performance.
            You acknowledge that we are not responsible for any loss of your digital asset resulting from theft,
            loss, or mishandling of digital asset private keys outside our control.
          </p>
          <p className="text-fs-body-md">
            We are not responsible for any digital asset market and we make no representations or warranties
            concerning the real or perceived value of digital assets as denominated in any quoted currency.
            Although we may provide historical and/or real-time data regarding the price of digital assets,
            we make no representations regarding the quality, suitability, truth, usefulness, accuracy,
            or completeness of such data, and you should not rely on such data for any reason whatsoever.
            You understand and agree that the value of digital assets can be volatile, and we are not in any
            way responsible or liable for any losses you may incur by using or transferring digital assets in
            connection with our Services.
          </p>
          <p className="text-fs-body-md">
            We do not provide investment advice and any content contained on the Site should not be considered
            as a substitute for tailored investment advice. The contents of our Site and the Services should
            not be used as a basis for making investment decisions.
          </p>
          <p className="text-fs-body-lg ">
            5. Accuracy of Information and Third Party Content.
          </p>
          <p className="text-fs-body-md">
            We endeavor to verify the accuracy of any information displayed, supplied,
            passing through or originating from the Services, but such information may
            not always be accurate or current. Accordingly, you should independently verify
            all information before relying on it, and any decisions or actions taken based upon
            such information are your sole responsibility.
          </p>
          <p className="text-fs-body-md">
            We make no representation or warranty of any kind, express or implied,
            statutory or otherwise, regarding the contents of the Services, information and
            functions made accessible through the Services, any hyperlinks to third party websites,
            or the security associated with the transmission of information through the Services,
            or any website linked to the Services.
          </p>
          <p className="text-fs-body-md">
            In using the Services, you may view content or services provided by third parties,
            including links to web pages and services of such parties ("Third Party Content").
            We do not control, endorse or adopt any Third Party Content and have no responsibility for Third Party Content,
            including, without limitation, material that may be misleading, incomplete, erroneous,
            offensive, indecent or otherwise objectionable in your jurisdiction. In addition, your dealings
            or correspondence with such third parties are solely between you and the third party.
            We are not responsible or liable for any loss or damage of any sort incurred as a result
            of any such dealings and your use of Third Party Content is at your own risk.
          </p>
          <p className="text-fs-body-lg ">
            6. User Contributions
          </p>
          <p className="text-fs-body-md">
            You represent and warrant that you own or control all rights in and to any content including,
            without limitation, all data you input into the Site or Services.
          </p>
          <p className="text-fs-body-md">
            You understand and acknowledge that you are responsible for any data you submit to the Site or Services,
            and you, not Company, have full responsibility for such content, including its legality, reliability,
            accuracy, and appropriateness.
          </p>
          <p className="text-fs-body-lg ">
            7. Limitation of Liability
          </p>
          <p className="text-fs-body-md">
            IN NO EVENT SHALL COMPANY, ITS AFFILIATES AND SERVICE PROVIDERS, OR ANY OF THEIR RESPECTIVE OFFICERS,
            DIRECTORS, AGENTS, EMPLOYEES OR REPRESENTATIVES, BE LIABLE FOR ANY LOST PROFITS OR ANY SPECIAL, INCIDENTAL,
            INDIRECT, INTANGIBLE, OR CONSEQUENTIAL DAMAGES, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE,
            STRICT LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH AUTHORIZED OR UNAUTHORIZED
            USE OF THE SITE, SERVICES, OR THIS AGREEMENT, EVEN IF AN AUTHORIZED REPRESENTATIVE OF COMPANY
            HAS BEEN ADVISED OF, KNEW OF, OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. FOR EXAMPLE
            (AND WITHOUT LIMITING THE SCOPE OF THE PRECEDING SENTENCE), YOU MAY NOT RECOVER FOR LOST PROFITS,
            LOST BUSINESS OPPORTUNITIES, OR OTHER TYPES OF SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE,
            OR CONSEQUENTIAL DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
            OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
          </p>
          <p className="text-fs-body-md">
            UNDER NO CIRCUMSTANCES SHALL WE BE REQUIRED TO DELIVER TO YOU ANY VIRTUAL CURRENCY AS DAMAGES,
            MAKE SPECIFIC PERFORMANCE OR ANY OTHER REMEDY. IF YOU WOULD BASE YOUR CALCULATIONS OF DAMAGES
            IN ANY WAY ON THE VALUE OF VIRTUAL CURRENCY, YOU AND WE AGREE THAT THE CALCULATION SHALL BE BASED
            ON THE LOWEST VALUE OF THE VIRTUAL CURRENCY DURING THE PERIOD BETWEEN THE ACCRUAL OF THE CLAIM AND
            THE AWARD OF DAMAGES.
          </p>
          <p className="text-fs-body-md">
            COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGES CAUSED IN WHOLE OR IN PART BY
            (A) UNSUPPORTED SOFTWARE OR HARDWARE; (B) THE MALFUNCTION, UNEXPECTED FUNCTION
            OR UNINTENDED FUNCTION OF ANY COMPUTER OR CRYPTOCURRENCY NETWORK, INCLUDING
            WITHOUT LIMITATION LOSSES ASSOCIATED WITH NETWORK FORKS, REPLAY ATTACKS,
            DOUBLE-SPEND ATTACKS, SYBIL ATTACKS, 51% ATTACKS, GOVERNANCE DISPUTES,
            MINING DIFFICULTY, CHANGES IN CRYPTOGRAPHY OR CONSENSUS RULES, HACKING OR
            CYBERSECURITY BREACHES; (C) THE CHANGE IN VALUE OF ANY DIGITAL ASSET; (D)
            ANY CHANGE IN LAW, REGULATION OR POLICY, OR (E) FORCE MAJEURE EVENT. THIS LIMITATION
            OF LIABILITY IS INTENDED TO APPLY WITHOUT REGARD TO WHETHER OTHER PROVISIONS OF THIS
            AGREEMENT HAVE BEEN BREACHED OR HAVE PROVEN INEFFECTIVE.
          </p>
          <p className="text-fs-body-lg ">
            8. Intellectual Property
          </p>
          <p className="text-fs-body-md">
            Unless otherwise indicated by us, all intellectual property rights and any content
            provided in connection with the Site or the Services, are the property of Company or
            our licensors or suppliers and are protected by applicable intellectual property laws.
            We do not give any implied license for the use of the contents of the Site or the Services.
          </p>
          <p className="text-fs-body-md">
            You accept and acknowledge that the material and content contained in or delivered by
            the Site or the Services is made available for your personal, lawful, non-commercial
            use only and that you may only use such material and content for the purpose of using
            the Site or the Services as set forth in this agreement.
          </p>
          <p className="text-fs-body-md">
            You further acknowledge that any other use of content from the Site or the Services
            is strictly prohibited and you agree not to infringe or enable others to infringe our
            intellectual property rights. You agree to retain all copyrighted and other proprietary
            notices contained in the materials provided via the Site or the Services on any copy you
            make of the material but failing to do so shall not prejudice Company’s intellectual property rights therein.
          </p>
          <p className="text-fs-body-md">
            You may not sell or modify materials derived or created from the Site or the Services or
            reproduce, display, publicly perform, distribute or otherwise use the materials in any
            way for any public or commercial purpose. Your use of such materials on any other website
            or on a file-sharing or similar service for any purpose is strictly prohibited.
            You may not copy any material or content derived or created from the Site or the Services without our
            express, written permission.
          </p>
          <p className="text-fs-body-md">
            Any rights not expressly granted herein to use the materials contained on or through
            the Site or the Services are reserved by Company in full.
          </p>
          <p className="text-fs-body-md">
            We respect the intellectual property of others. If you believe that your
            intellectual property rights are being infringed, please email
            <a href="mailto:info@mercurx.com"> info@mercurx.com</a>.
          </p>

          <p className="text-fs-body-lg ">
            9. Indemnification
          </p>
          <p className="text-fs-body-md">
            You agree to indemnify and hold harmless Company, its affiliates, subsidiaries,
            directors, managers, members, officers, and employees from any and all claims,
            demands, actions, damages, losses, costs or expenses, including without limitation,
            reasonable legal fees, arising out of or relating to your or any other person’s
            use of your credentials or User Account in connection with: (a) use of the Site or Services;
            (b) breach of these Terms of Use or any other policy; (c) feedback or submissions you provide;
            (d) false, incomplete, or misleading information relied upon by us to verify your identity and
            source of funds, where applicable; or (e) violation of any rights of any other person or entity;
            provided however, that you shall not indemnify Company for claims or losses arising out of Company’s
            gross negligence or willful misconduct. This indemnity shall apply to your successors and assigns
            and shall survive any termination or cancellation of this agreement.
          </p>
          <p className="text-fs-body-lg ">
            10. Governing Law, Legal Disputes, and Jurisdiction
          </p>
          <p className="text-fs-body-md">
            All matters relating to the Website and these Terms of Use and any dispute or claim arising
            therefrom or related thereto (in each case, including non-contractual disputes or claims),
            shall be governed by and construed in accordance with the laws of Turkey. For litigation arising
            from this Agreement, the Parties submit to the exclusive jurisdiction of the arbitration in Turkey.
            Furthermore, if any arbitration is necessary to enforce the terms of this Agreement,
            the prevailing party will be entitled to reasonable attorneys' fees and costs.
          </p>
          <p className="text-fs-body-md">
            EXCEPT WHERE PROHIBITED BY APPLICABLE LAW, YOU AGREE THAT ANY CLAIM
            YOU MAY HAVE AGAINST US SHALL BE BROUGHT INDIVIDUALLY AND YOU SHALL
            NOT JOIN SUCH CLAIM WITH CLAIMS OF ANY OTHER PERSON OR ENTITY OR BRING, JOIN,
            OR PARTICIPATE IN A CLASS ACTION AGAINST US.
          </p>
          <p className="text-fs-body-md">
            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE
            SERVICES OR SITE MUST BE COMMENCED WITHIN ONE YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE,
            SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
          </p>
          <p className="text-fs-body-lg ">
            11. Confidential Information
          </p>
          <p className="text-fs-body-md">
            Definition. Each party to these Terms (a "Receiving Party") may have access to, or become acquainted with,
            certain non-public confidential information of the other party (a "Disclosing Party") including without
            limitation all information clearly identified as confidential at the time of disclosure (“Confidential Information”).
            You and Company further agree that, subject to the rights and licenses granted herein, each party's Confidential
            Information shall include all non-public information regarding the Disclosing party, including without limitation
            any customer, customer prospect, the terms, conditions and pricing set forth in any agreement and its Order Form(s),
            marketing, technical, business and/or strategic plans or information provided by the Disclosing Party to the
            Receiving party in the performance of the Services under the Agreement.
          </p>
          <p className="text-fs-body-md">
            Use and Disclosure. Each party agrees as follows: (a) to use the Confidential Information of the Disclosing
            Party only for the purposes permitted by the Agreement, or as otherwise permitted by the Agreement;
            (b) to take all reasonable steps to ensure that the Disclosing Party’s Confidential Information is
            not disclosed or distributed by the Receiving Party’s employees or agents in violation of the Agreement,
            but in no event will either party use less effort to protect the Confidential Information of the Disclosing
            Party than it uses to protect its own Confidential Information of like importance; (c) to restrict access to
            the Confidential Information disclosed by the Disclosing Party to such of the Receiving Party’s employees,
            agents and third parties, if any, who have a need to have access and who have been advised of and have agreed
            in writing or are otherwise bound to treat such information in accordance with the Agreement; and (d) to
            return or destroy all Confidential Information of the Disclosing Party’s written request, after termination of
            the Agreement. The Receiving Party will not be obligated under this confidentiality section with respect to
            information that: (a) is or has become readily publicly available through no act or omission of the Receiving
            Party or its employees or agents; (b) is received from a third party lawfully in possession of such information
            and the Receiving Party has no knowledge of any disclosure restrictions on such third party to disclose such
            information; (c) is disclosed to a third party by the Disclosing Party without restriction on disclosure;
            (d) was rightfully in the possession of the Receiving Party without restriction prior to its disclosure by
            the Disclosing Party; or (e) was independently developed by employees or consultants of the Receiving Party
            without reliance on, or reference to, such Confidential Information.
          </p>
          <p className="text-fs-body-md">
            Permitted Disclosures. Notwithstanding the foregoing, Confidential Information may be disclosed as
            required by any governmental agency, provided that before disclosing such information the Disclosing
            Party must provide the Receiving Party with sufficient advance notice of the agency’s request for the
            information to enable the Receiving Party to exercise any rights it may have to challenge or limit the
            agency’s authority to receive such Confidential Information, to the extent permitted by applicable law.
          </p>
          <p className="text-fs-body-md">
            12. Miscellaneous
          </p>
          <p className="text-fs-body-md">
            Force Majeure. If by reason in whole or in part of any Force Majeure Event, either you or Company is
            delayed or prevented from complying with these Terms of Use, then such delay or non-compliance shall
            not be deemed to be a breach and no loss or damage shall be claimed by you or Company by reason thereof.
            "Force Majeure Event" means any event beyond the party’s reasonable control, including, but not limited to,
            flood, extraordinary weather conditions, earthquake, or other act of God, fire, war, insurrection, riot,
            labor dispute, accident, action of government, communications, power failure, failure in bank performance,
            or equipment or software malfunction including network splits or "forks" or unexpected changes in a computer
            network upon which the Services rely.
          </p>
          <p className="text-fs-body-md">
            Taxes. It is your responsibility to determine what, if any, taxes apply due to your use of the Services,
            and it is your responsibility to report and remit the correct tax to the appropriate tax authority.
            Company is not responsible for determining and will not advise whether taxes apply to your use of the Services.
          </p>
          <p className="text-fs-body-md">
            Severability and Reformation. In the event that any provision of these Terms of Use is unenforceable
            under applicable law, the validity or enforceability of the remaining provisions will not be affected.
            To the extent any provision of these Terms of Use is judicially determined to be unenforceable,
            a court of competent jurisdiction may reform any such provision to make it enforceable.
          </p>
          <p className="text-fs-body-md">
            Waiver. No waiver by Company of any term or condition set forth in these Terms of Use shall be deemed
            a further or continuing waiver of such term or condition or a waiver of any other term or condition,
            and any failure of Company to assert a right or provision under these Terms of Use shall not constitute
            a waiver of such right or provision.
          </p>
          <p className="text-fs-body-md">
            Assignment. These Terms of Use shall be binding on your successors, heirs, personal representatives,
            and assigns. You may not assign or transfer any of your rights or obligations without prior written
            consent of Company, which may be withheld in Company’s sole discretion. We may assign rights or delegate
            duties under these Terms of Use in our sole discretion.
          </p>
          <p className="text-fs-body-md">
            Relationship of the Parties. Nothing in this agreement is intended to, nor shall create any partnership,
            joint venture, agency, consultancy or trusteeship. You and Company are independent contractors for purposes
            of this agreement.
          </p>
          <p className="text-fs-body-md">
            Contact Information. You may contact us via
            <a href="mailto:info@mercurx.com"> info@mercurx.com</a>.
          </p>
          <p className="text-fs-body-md">
            13. Law Enforcement Inquiries and Service of Subpoenas
          </p>
          <p className="text-fs-body-md">
            Company will comply with any duly issued subpoena served on us through the proper legal channels.
            We do not consent to service of a subpoena by any other method.
          </p>
          <p className="text-fs-body-md">
            We will consider whether to respond to all other law enforcement inquiries on a case-by-case basis,
            and any such response is voluntary and made in our sole discretion. Kindly send any such request to
            <a href="mailto:info@mercurx.com"> info@mercurx.com</a>. By sending an inquiry to
            <a href="mailto:info@mercurx.com"> info@mercurx.com</a>, you expressly consent and agree that any and all such
            communications become the sole property of Company and that Company shall have the right, in its sole discretion,
            to publish or disseminate any such communications, regardless of the content of the communications.
          </p>
          <p className="text-fs-body-md">
            If you are requesting information from Company that is likely to prevent death or serious bodily harm,
            you may contact us on an expedited basis. Together with the appropriate documents listed above,
            send an email to ? with "IMMINENT RISK" in the subject line. In the body of the email,
            include a brief attestation describing the imminent risk, and Company will endeavor to respond on an expedited basis.
            We expressly reserve all rights in considering whether or not to respond, which is on a voluntary basis and
            at our sole discretion, except in instances where we have been served a duly issued subpoena that has been
            issued through the proper legal channels.
          </p>
          <p className="text-fs-body-md">
            The information and data supplied in response to requests will be collected and provided to the fullest and
            most accurate possible extent. However, nothing in such information and data can be ensured to contain no errors,
            mistakes, misrepresentations or failures etc. Such errors, mistakes, misrepresentations or failures etc.
            can be the result of human agency, program or process imperfections. THEREFORE, NEITHER COMPANY NOR ANY
            OF OUR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR OUTSOURCED ADVISORS MAKES ANY REPRESENTATION,
            WARRANTY OR GUARANTEE WHATSOEVER WITH RESPECT TO THE INFORMATION AND DATA AND, IN PARTICULAR, AS TO
            THE ACCURACY OR COMPLETENESS OF THE INFORMATION AND DATA.
          </p>
          <p className="text-fs-body-md">
            The information and data supplied in response to requests will be collected and provided to
            the fullest and most accurate possible extent. However, nothing in such information and data can be ensured
            to contain no errors, mistakes, misrepresentations or failures etc. Such errors, mistakes, misrepresentations
            or failures etc. can be the result of human agency, program or process imperfections. THEREFORE, NEITHER COMPANY
            NOR ANY OF OUR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR OUTSOURCED ADVISORS MAKES ANY REPRESENTATION,
            WARRANTY OR GUARANTEE WHATSOEVER WITH RESPECT TO THE INFORMATION AND DATA AND, IN PARTICULAR, AS TO THE ACCURACY
            OR COMPLETENESS OF THE INFORMATION AND DATA.
          </p>
          Nothing in this section shall be deemed or construed as non-willingness to provide the requested information and data.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAccept} disabled={!acceptEnabled}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    sign: (creds) => {
      dispatch(signUpRequest(creds));
    },
  };
};

export default connect(null, mapDispatchToProps)(SignUp);
