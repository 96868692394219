import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button,Card } from 'react-bootstrap';
import { unsubscribeRequestAction } from '../../store/account/userActions';
import './Unsubscribe.scss';
import { NavLink, useHistory } from 'react-router-dom';

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

function Unsubscribe({ ...props }) {
  const history = useHistory();
  const { unsubscribe } = props;
  const [state, setState] = useState({
    data: {
      token: params.token,
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { data } = state;
    console.log(data);
    unsubscribe(data);
    setState({
      data: {
        token: '',
      },
      errors: {},
    });
    setTimeout(() => {
      history.push('/');
    }, 2000);
  };

  const handleOut = () => {
    history.push('/');
  };

  return (
    <Row className="d-flex justify-content-center align-items-center">
      <Col className="public-layout-col d-flex 
      justify-content-center align-items-center bg-white px-1 py-2">
        <div className="public-layout-image d-md-flex d-none flex-column
         justify-content-center align-items-center bg-primary px-1 py-2">
          <div className="text-fs-head-lg text-center text-light px-2">
          Welcome to the exciting world of <span>MercurX</span>
          </div>
          <div className="text-fs-body-md text-center text-light px-2"></div>
        </div>
        
        <div className="d-flex flex-column justify-content-center
         aling-items-center px-4 mx-2 public-layout-form">
          {/* <div className='mb-4 mx-2'>Are you sure you want
           to unsubscribe from our mailings?</div> */}
          <div className='mb-4 mx-2'>
                  <Card.Title>Are you sure you want to unsubscribe from our mailing ?</Card.Title>
                </div>
          <div className="col-12 d-flex justify-content-between mb-4 mt-3">
            <Button onClick={handleSubmit}  type="submit" className="col-5 bg-mercurx-primary">
              Yes
            </Button>
            <Button onClick={handleOut} type="button" className="col-5 bg-mercurx-primary">
              No
            </Button>
          </div>
          <NavLink className="d-flex justify-content-center m-0 text-primary" to="/login">
            Back to Login
          </NavLink>
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    unsubscribe: (creds) => {
      dispatch(unsubscribeRequestAction(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);
