import React, { useState } from 'react';
import './Staking.scss';
import mercurxMiniIcon from '../../assets/img/logo/mercurx-logo-mini.png';
import { Button, Card, Col, Container, Dropdown, Row } from 'react-bootstrap';
import { stakingMultiplierInfo } from '../../helpers/stakingMultiplierInfo';
import { 
  // Cell, Legend, Pie, PieChart, ResponsiveContainer, 
  Sector } from 'recharts';
import { mainColors } from '../../helpers/colors';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { sortKeys, sortTypes } from './stakingConstants';
import { connect } from 'react-redux';
import {
  getStakingStatisticsRequestAction,
  getStakingHistoryRequestAction,
  postStakingRequestAction,
  sortStakingHistorySortData,
  sortingStakingHistoryAction,
} from '../../store/staking/stakingActions';
// eslint-disable-next-line max-len
import { checkAllConditionForStake } from '../../helpers/verificationHelper';
// eslint-disable-next-line max-len
import MercurXStakingHistoryTable from '../../components/MercurXStakingHistoryTable/MercurXStakingHistoryTable';
import Subscribe from '../../components/MercurXSubscribe/Subscribe';
import wallet from '../../helpers/wallet';
import { useEffect } from 'react';
import { logoutRequestAction } from '../../store/account/userActions';
import { getProjects } from '../../store/project/projectActions';
import {
  walletAccountDetailModalAction,
  WalletConnectModalAction,
} from '../../store/wallet/walletActions';
import Swal from 'sweetalert2';
import { setLoadingAction, setLoadingFullAction } from '../../store/loading/loadingActions';
import Web3 from 'web3';
import { transactionRequest } from '../../store/transaction/transactionActions';
import { ethers } from 'ethers';
import { abiRequestAction } from '../../store/abi/abiActions';
export const checkUserVerified = (user) => {
  return user?.is_active?.toString().toLowerCase() === 'true';
};
export const checkUserKyc = (user) => {
  return user?.kyc?.length > 0 && user.kyc[0]?.kyc_status === 'Approved';
};
function Staking({ ...props }) {
  const {
    getStakingHistoryRequest,
    getStakingStatisticsRequest,
    postStakingRequest,
    putStakingRequest,
    stakingHistorySortData,
    stakingStatisticsData,
    setSortData,
    sortingStakingHistory,
    balance_,
    accounts,
    setLoading,
    setLoadingFull,
    abiHistory,
    abiHistoryRequest,
    isLoading,
    user,
    tierData,
    projects,
    getProjects,
    transactionRequest,
    activeProjects,
  } = props;

  function calculateFormat(x) {

    const formatBalance = (Math.floor((Number(x) / 10000) * 100) / 100).toString().split('.');

    const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
    const secondValueFormatBalance = formatBalance[1] || "0";
    return firstValueFormatBalance + ',' + secondValueFormatBalance;
  }
  function calculateNewBalance() {

    const formatBalance =
      (Math.floor((Number(balance_) / 10000) * 100) / 100).toString().split('.');

    const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
    const secondValueFormatBalance = formatBalance[1] || "0";
    return firstValueFormatBalance + ',' + secondValueFormatBalance;
  }
  const [selectedMultiplier, setSelectedMultiplier] = useState(stakingMultiplierInfo[0]);
  const [userWalletBalance, setUserWalletBalance] = useState(1000.86);
  const [valueToBeMultiplied, setValueToBoMultiplied] = useState(0);
  const [pieActiveIndex, setPieActiveIndex] = useState(0);
  const [txs, setTxs] = useState([]);
  const { utils } = require('ethers');

  useEffect(() => {
    handleAbi();
    console.log(stakingStatisticsData)
  }, []);

  useEffect(() => {
    getProjects();
  }, []);
  // Bu kodun bulunduğu bileşen içerisinde
  function get_staking_history() {
    const address = utils.getAddress(accounts?.[0]);
    const payload = {
      id: address,
    };
    const payload1 = {
      wallet: address,
    };
    getStakingStatisticsRequest(payload1);
    getStakingHistoryRequest(payload);
  }
  useEffect(() => {
    if (accounts?.[0]) {
      wallet.getMyBalance(projects?.[0].token.address);
      get_staking_history();
    }
  }, [accounts]);

  const handleAbi = () => {
    abiHistoryRequest();
  };

  function isActiveMultiplier(multiplierId) {
    if (selectedMultiplier.id === multiplierId) {
      return true;
    }
  }
  function convertStartTime(currentDate) {
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;
  }

  function convertEndTime(currentDate, months) {
    const newDate = new Date(currentDate);
    newDate.setMinutes(newDate.getMinutes() + months);
    const year = newDate.getFullYear();
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const day = newDate.getDate().toString().padStart(2, '0');
    const hours = newDate.getHours().toString().padStart(2, '0');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');
    const seconds = newDate.getSeconds().toString().padStart(2, '0');
    const milliseconds = newDate.getMilliseconds().toString().padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;
  }
  function calculateBalance() {
    const formatBalance = (Math.floor((Number(balance_) / 10000) * 100) / 100).toString();
    const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
    const secondValueFormatBalance = formatBalance[1] || '0';

    return formatBalance;
  }

  function onPieEnter(e, index) {
    setPieActiveIndex(index);
  }
  function onChangeStakingMerx(e) {
    const inputValue = e.target.value.replace(',', '.');
    if (/^\d*\.?\d{0,2}$/.test(inputValue) && Number(inputValue) <= Number(calculateBalance())) {
      setValueToBoMultiplied(inputValue);
    }
  }
  
  const stakeToken = async (e,valueToBeMultiplied) => {
    e.preventDefault();
    setTxs([]);
    setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
    const data_count = valueToBeMultiplied;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    const signerAddress = await signer.getAddress();
    const web3 = new Web3(window.ethereum);
    await wallet.controlAndSwitchOrAddNetwork();
    await window.ethereum.enable();
    const mercurx_token = new web3.eth.Contract(
      abiHistory?.[0]?.['MERX_abi'],
      projects?.[0].token.address,
    );
    const mercurx_stake = new web3.eth.Contract(
      abiHistory?.[0]?.['MERX_staking_abi'],
      projects?.[0].token.staking_contract.contract_address,
    );
    const etherMiktari = data_count;
    console.log('miktar :' +etherMiktari)
    console.log('signerAddress :' +signerAddress)
    console.log('mercurx contract :' +projects?.[0].token.address)
    console.log('staking contract :' +projects?.[0].token.staking_contract.contract_address)
    
    const project_id = 1;
    const project_name = projects?.[0].token.name;
    const action = 'staking';
    const token_count = data_count;
    const token_address = projects?.[0].token.address;
    console.log('staking_contract:' +
     projects?.[0].token.staking_contract.contract_address)
    console.log(projects?.[0].token.decimals)
    const transaction_time = new Date();
    const user_public_address = signerAddress;
    try {
      const approve_transaction = await mercurx_token.methods
         .approve(
          projects?.[0].token.staking_contract.contract_address,
           web3.utils.toWei(String(Number(etherMiktari) * (10**projects?.[0].token.decimals)),
            'wei'),
         )
         .send({
           from: signerAddress,
         });
      const transaction = await mercurx_stake.methods
        .stake(
          web3.utils.toWei(String(Number(etherMiktari) * (10**projects?.[0].token.decimals) ), 
      'wei'), selectedMultiplier.index) 
        .send({
          from: signerAddress,
          to:  projects?.[0].token.staking_contract.contract_address,
          data: [web3.eth.abi.encodeFunctionSignature('whitdrawETH()'), 0],
        });
      wallet.getMyBalance(projects?.[0].token.address); 
      const transaction_status = true;
      const transaction_hash = transaction.transactionHash;
      

      const payload2 = {
        project_id,
        project_name,
        token_count,
        user_public_address,
        token_address,
        transaction_hash,
        transaction_time,
        transaction_status,
        action,
      };
      transactionRequest(payload2);
      postStaking(transaction_hash,token_count);

      Swal.fire({
        icon: 'success',
        iconColor: mainColors.primary,
        text: 'Transaction succeed',
        confirmButtonColor: mainColors.primary,
        html:
          '<a href=https://testnet.bscscan.com/tx/' +
         transaction.transactionHash +
          " target='_blank'> Check Detail Transaction !</a>",
      });
      setLoadingFull({ isLoading: false });
      get_staking_history();
    } catch (err) {
      if (err?.receipt?.transactionHash) {
        Swal.fire({
          icon: 'error',
          iconColor: '#E40039',
          title: 'Transaction is Failed',
          confirmButtonColor: '#E40039',
          // eslint-disable-next-line max-len, no-template-curly-in-string
          html:
            '<a href=https://testnet.bscscan.com/tx/' +
            err.receipt.transactionHash +
            " target='_blank'> Check Detail Transaction !</a>",
        });
        setLoadingFull({ isLoading: false });
        const transaction_status = false;
        const transaction_hash =
        err.receipt.transactionHash;
        const payload2 = {
          project_id,
          project_name,
          token_count,
          user_public_address,
          token_address,
          transaction_hash,
          transaction_time,
          transaction_status,
          action,
        };
        transactionRequest(payload2);
      } else {
        Swal.fire({
          icon: 'warning',
          iconColor: '#E40039',
          confirmButtonColor: '#E40039',
          text: err.message,
        });
        setLoadingFull({ isLoading: false });
      }
    }
  };

  function postStaking(transaction_hash,token_count) {
    const currentDate = new Date();
    const startDate = convertStartTime(currentDate);
    const address = utils.getAddress(accounts?.[0]);
    const payload = {
      symbol: projects?.[0].token.symbol,
      wallet: address,
      tx_hash: transaction_hash,
      value: token_count,
      start_lock_time: startDate, //TODO: start_lock_time yerine start_stake_time
      claim_status: true,
    };
    console.log(payload)
    postStakingRequest(payload);
  }

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
      color,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={color}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={color}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={color}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={color} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={color} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${calculateFormat(value * 10000)}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  function changeSortType(sortType) {
    const newStakingHistorySortData = { ...stakingHistorySortData, sortType };
    setSortData(newStakingHistorySortData);
    sortingStakingHistory();
  }

  function changeSortKey(sortKey) {
    const newStakingHistorySortData = { ...stakingHistorySortData, sortKey };
    setSortData(newStakingHistorySortData);
    sortingStakingHistory();
  }
  let chartValues = 0;

  return (
    <Container>
      <Row className="mb-5">
        {stakingMultiplierInfo.map((item, index) => {
          return (
            <Col key={index} sm={12} md={4}>
              <Card
                className={`my-card 
                   py-2 px-3 shadow cursor-pointer`}
                onClick={() => setSelectedMultiplier(stakingMultiplierInfo[index])}
              >
                <img
                  className="img-fluid mx-auto mb-2"
                  alt="mercurx-mini-icon"
                  src={mercurxMiniIcon}
                  width={50}
                />
                <div className="text-fs-head-xs mx-auto">{item.title}</div>
                <div>REQUIREMENT</div>
                <div>{item.requirement}</div>
                <hr></hr>
                <div style={{ height: '160px' }}>
                  <div className='text-primary'>Key Features</div>
                  {item.features.map((feature, fIndex) => {
                    return (
                      <div
                        key={fIndex}
                        className={`${feature.highlight} text-fs-body-md`}
                      >
                        • {feature.content}
                      </div>
                    );
                  })}</div>
                <hr></hr>
                <Row>
                  {checkAllConditionForStake(user, accounts) ? (
                    <Button onClick={(e) => stakeToken(e, item.value)}>
                      <span className="ms-1">Stake {item.value} MERX</span>
                    </Button>
                  ) : (
                    <Button disabled={true}>
                      <span className="ms-1">Stake {item.value} MERX</span>
                    </Button>
                  )}
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row>
              <Col>
                <div className="border rounded p-2">
                  <div className="d-flex align-items-center border-bottom-0">
                    {user ? (
                      <MercurXIcons name="MdDone" color="#28a745" size="24" />
                    ) : (
                      <MercurXIcons name="MdPriorityHigh" color="#ffc107" size="24" />
                    )}
                    <span className="h6 mb-0 mx-auto">
                      {user ? 'Account Enabled' : 'Account Disabled'}
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="border rounded p-2">
                  <div className="d-flex align-items-center border-bottom-0">
                    {accounts ? (
                      <MercurXIcons name="MdDone" color="#28a745" size="24" />
                    ) : (
                      <MercurXIcons name="MdPriorityHigh" color="#ffc107" size="24" />
                    )}
                    <span className="h6 mb-0 mx-auto">
                      {accounts ? 'Wallet Enabled' : 'Wallet Disabled'}
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="border rounded p-2">
                  <div className="d-flex align-items-center border-bottom-0">
                    {checkUserVerified(user) ? (
                      <MercurXIcons name="MdDone" color="#28a745" size="24" />
                    ) : (
                      <MercurXIcons name="MdPriorityHigh" color="#ffc107" size="24" />
                    )}
                    <span className="h6 mb-0 mx-auto">
                      {checkUserVerified(user) ? 'Mail Verified' : 'Mail Not Verified'}
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="border rounded p-2">
                  <div className="d-flex align-items-center border-bottom-0">
                    {checkUserKyc(user) ? (
                      <MercurXIcons name="MdDone" color="#28a745" size="24" />
                    ) : (
                      <MercurXIcons name="MdPriorityHigh" color="#ffc107" size="24" />
                    )}
                    <span className="h6 mb-0 mx-auto">
                      {checkUserKyc(user) ? 'Kyc Approved' : 'Kyc Unapproved'}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
      <Row className="px-3 mb-5">
        <div >
          <Row className="align-items-center mb-0">
          </Row>
        </div>
      </Row>
      <Row className="mb-0">
        <Col sm={12} md={4}>
        </Col>
      </Row>
      <div className="text-fs-head-sm mb-2"> My Stake History</div>
      <Row
        id="staking-history-container"
        className="d-flex align-items-center justify-content-between"
      >
        <Col>
          <div id="token-sorting-section" className="d-flex align-items-center py-2">
            <Dropdown className="me-2 sales-table-button">
              <Dropdown.Toggle className="d-flex align-items-center">
                <MercurXIcons name="MdSort" size="18" />
                <span className="ms-1">{sortTypes[stakingHistorySortData.sortType].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortTypes.map((sortType, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortType(index)}
                    >
                      <MercurXIcons name={sortType.icon} size="18" />
                      <span className="ms-2">{sortType.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="sales-table-button">
              <Dropdown.Toggle className="d-flex align-items-center">
                <MercurXIcons name="BiFilterAlt" size="18" />
                <span className="ms-1">{sortKeys[stakingHistorySortData.sortKey].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortKeys.map((sortKey, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortKey(index)}
                    >
                      <span>{sortKey.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        <MercurXStakingHistoryTable />
      </Row>
      <Subscribe />
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    stakingHistoryData: state.stakingReducer.stakingHistoryData,
    stakingStatisticsData: state.stakingReducer.stakingStatisticsData,
    stakingHistorySortData: state.stakingReducer.stakingHistorySortData,
    balance_: state.walletReducer.balance_,
    abiHistory: state.abiReducer.abiHistory,
    isLoading: state.loadingReducer.isLoading,
    tierData: state.tierReducer.tierData,
    accounts: state.walletReducer.accounts,
    user: state.userReducer.user,
    projects: state.projectReducer.projects,
    activeProjects: state.projectReducer.activeProjects,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    abiHistoryRequest: (payload) => {
      dispatch(abiRequestAction(payload));
    },
    setLoading: (payload) => {
      dispatch(setLoadingAction(payload));
    },
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
    logoutRequest: (payload) => {
      dispatch(logoutRequestAction(payload));
    },
    walletAccountDetailModalRequest: (payload) => {
      dispatch(walletAccountDetailModalAction(payload));
    },
    WalletConnectModalRequest: (payload) => {
      dispatch(WalletConnectModalAction(payload));
    },
    getStakingHistoryRequest: (payload) => {
      dispatch(getStakingHistoryRequestAction(payload));
    },
    getStakingStatisticsRequest: (payload) => {
      dispatch(getStakingStatisticsRequestAction(payload));
    },
    postStakingRequest: (payload) => {
      dispatch(postStakingRequestAction(payload));
    },
    setSortData: (payload) => {
      dispatch(sortStakingHistorySortData(payload));
    },
    sortingStakingHistory: (payload) => {
      dispatch(sortingStakingHistoryAction(payload));
    },
    transactionRequest: (creds) => {
      dispatch(transactionRequest(creds));
    },
    getProjects: (payload) => {
      dispatch(getProjects(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Staking);
