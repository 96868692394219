/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import './Login.scss';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  accountDetailsRequestAction,
  loginActionRequest,
  verify2faRequestAction,
} from '../../store/account/userActions';
import { useDispatch } from 'react-redux';
import { InputGroup } from 'react-bootstrap';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
function Login({ ...props }) {
  const { loginRequest, user, verify2faRequest,accountDetailsRequest, } = props;

  const [state, setState] = useState({
    data: {
      username: '',
      password: '',
    },
    errors: {},
  });

  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('user');
    console.log(user);
    if (user?.two_factor_auth) {
      setShowModal(true);
    }
  }, [user?.two_factor_auth]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { data } = state;
    const errors = validate();
    console.log(errors);
    const payload = {
      data,
      history,
      // history: porps.history
    };
    if (Object.keys(errors).length === 0) {
      loginRequest(payload);
      if (user?.two_factor_auth) {
        setShowModal(true);
      }
    } else {
      setState({
        errors,
      });
    }
    
    setTimeout(() => {
      accountDetailsRequest();
    }, 3000);
  };
  const validate = () => {
    const { data } = state;
    const errors = {};
    console.log(data);
    if (data?.username === '') {
      errors.username = 'Username cannot be blank.';
    }
    if (data?.password === '') {
      errors.password = 'Password cannot be blank.';
    }

    return errors;
  };

  const handleChange = (e) => {
    setState({
      data: {
        ...state.data,
        [e.target.id]: e.target.value,
      },
      errors: {
        ...state.errors,
        [e.target.id]: '',
      },
    });
  };
  const handleVerify = async () => {
    try {
      const userId = user.id;
      const is_enabled = user.two_factor_auth;
      const totp_code = inputValue.replace(/-/g, '');
      console.log(totp_code);
      // totp_code = totp_code.trim();
      const payload = {
        userId: userId,
        totp_code: totp_code,
        is_enabled: is_enabled,
        history,
        loginPayload: {
          username: data.username,
          password: data.password,
        },
      };

      console.log(payload);
      await verify2faRequest(payload);

      // State'i sıfırla
      setInputValue('');

      setShowModal(false);
      // dispatch(verify2faRequestAction(payload));
      dispatch(verify2faRequestAction({ userId, totp_code, is_enabled, history }));
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  const handleInputChange = (e) => {
    let value = e.target.value;
    value = value.replace(/-/g, '');
    value = value.replace(/[^0-9]/g, '').slice(0, 6);
    if (value.length > 3) {
      value = value.slice(0, 3) + '-' + value.slice(3);
    }
    setInputValue(value);
  };
  const { data, errors } = state;
  const handleModalEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleVerify();
    }
  };
  return (
    <Row className="d-flex justify-content-center align-items-center">
      <Col className="public-layout-col d-flex justify-content-center align-items-center bg-white px-1 py-2">
        <div className="public-layout-image d-md-flex d-none flex-column justify-content-center align-items-center bg-primary px-1 py-2">
          <div className="text-fs-head-lg text-center text-light px-2">
            Welcome to the exciting world of <span>MercurX</span>
          </div>
          <div className="text-fs-body-md text-center text-light px-2">
            Step into the dynamic sphere of MercurX, where each exploration promises fascinating
            discoveries in the world of digital currency and blockchain!
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center aling-items-center px-4 mx-2 public-layout-form">
          <Form id="loginForm" onSubmit={handleSubmit}>
            <Form.Group controlId="username">
              <Form.Label className="text-fs-body-lg text-t-head-color">Email address</Form.Label>
              <Form.Control
                name="username"
                type="email"
                placeholder="Enter email"
                onChange={handleChange}
                value={data?.username}
                isInvalid={errors?.username}
              />
              <Form.Label className="text-danger text-fs-body-sm mercurx-error-label">
                {errors?.username && '*' + errors?.username}
              </Form.Label>
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label className="text-fs-body-lg text-t-head-color">Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={passwordVisibility ? 'text' : 'password'}
                  placeholder="Password"
                  onChange={handleChange}
                  value={data?.password}
                  isInvalid={errors?.password}
                />
                <InputGroup.Text
                  id="basic-addon2"
                  className="d-flex justify-content-between align-items-center input-group-text-custom"
                >
                  <Col>
                    <div onClick={() => setPasswordVisibility((prevVisibility) => !prevVisibility)}>
                    <MercurXIcons
                    name={passwordVisibility ? 'BsEyeSlash' : 'BsEye'}
                    iconClassName="mercurx-password-icon"
                  />
                    </div>
                  </Col>
                </InputGroup.Text>
              </InputGroup>
              <Form.Label className="text-danger text-fs-body-sm mercurx-error-label">
                {errors?.password && '*' + errors?.password}
              </Form.Label>
            </Form.Group>

            <Button
              className="mb-4 mt-3 bg-mercurx-primary col-sm-12"
              variant="primary"
              type="submit"
              onClick={() => {
                if (user?.two_factor_auth) {
                  setShowModal(true);
                }
              }}
            >
              Login
            </Button>
          </Form>

          <NavLink
            className="forgotpassword-link d-flex justify-content-center m-2 text-primary"
            to="/forgotpassword"
          >
            Forgot Password?
          </NavLink>

          <Col className="m-1 d-flex justify-content-center">
            <NavLink className="text-fs-body-md text-t-body-color" to="/signup">
              Don't have an account yet?
            </NavLink>
            &nbsp;
            <NavLink className="signup-link text-primary" to="/signup">
              SignUp
            </NavLink>
          </Col>
        </div>
      </Col>
      {/* Two Factor Auth Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Two Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Group className="mb-3">
              <Form.Label>Enter 2FA code:</Form.Label>
              <Form.Control
                type="text"
                placeholder="XXX-XXX"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleModalEnterKeyPress}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleVerify()}>
            Enter
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user || { two_factor_auth: false },
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: (creds) => {
      dispatch(loginActionRequest(creds));
    },
    accountDetailsRequest: () => {
      dispatch(accountDetailsRequestAction());
    },
    verify2faRequest: (payload) => {
      dispatch(verify2faRequestAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
