import { useEffect, useState } from 'react';
import { Pagination, Table, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import './MercurXLockingHistoryTable.scss';
import Button from 'react-bootstrap/Button';
// eslint-disable-next-line max-len
import wallet from '../../helpers/wallet';
import { putLockingRequestAction } from '../../store/locking/lockingActions';
import Swal from 'sweetalert2';
import Web3 from 'web3';
import * as loadingActionTypes from '../../store/loading/loadingActionTypes';
import { transactionRequest } from '../../store/transaction/transactionActions';
import { ethers } from 'ethers';
import { abiRequestAction } from '../../store/abi/abiActions';
import { setLoadingAction, setLoadingFullAction } from '../../store/loading/loadingActions';
import { mainColors } from '../../helpers/colors';
function MercurXLockingHistoryTable(props) {
  const {
    lockingHistoryData,
    balance_,
    accounts,
    setLoading,
    setLoadingFull,
    abiHistory,
    abiHistoryRequest,
    putLockingRequest,
    isLoading,
    transactionRequest,
    project,
  } = props;
  const [txs, setTxs] = useState([]);
  const handleAbi = () => {
    abiHistoryRequest();
  };
  // Şu anki yerel tarih ve saat
  const dateObj = new Date();
  useEffect(() => {
    wallet.getMyBalance('0x0f1C0c3435e866ec002BbfA1C30cb867453b8920');
  }, [accounts]);
  const maxRowCountPerPage = 5;
  const maxShowingPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [renderedData, setRenderedData] = useState([]);
  const [renderedPages, setRenderedPages] = useState([]);
  const [lastPageOfPagination, setLastPageOfPagination] = useState(maxShowingPage);
  function calculateFormat(x) {

    const formatBalance = (Math.floor((Number(x) / 10000) * 100) / 100).toString().split('.');

    const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
    const secondValueFormatBalance = formatBalance[1] || "0";
    return firstValueFormatBalance + ',' + secondValueFormatBalance;
  }
  useEffect(() => {
    if (lockingHistoryData) {
      setCurrentPage(1);
      const tempPages = [];
      for (
        let index = 0;
        index < Math.ceil(lockingHistoryData.length / maxRowCountPerPage);
        index++
      ) {
        tempPages.push(index + 1);
      }
      setPages([...tempPages]);
      setRenderedPages([
        ...tempPages.slice(lastPageOfPagination - maxShowingPage, lastPageOfPagination),
      ]);
    }
  }, [lockingHistoryData]);

  useEffect(() => {
    if (lockingHistoryData && currentPage) {
      const firstIndex = maxRowCountPerPage * currentPage - maxRowCountPerPage;
      const lastIndex = maxRowCountPerPage * currentPage;
      const tempRendered = lockingHistoryData?.slice(firstIndex, lastIndex);
      setRenderedData([...tempRendered]);
    }
  }, [currentPage, lockingHistoryData]);

  function scrollToLockingHistoryContainer() {
    const lockingHistory = document.getElementById('locking-history-container');
    if (lockingHistory) {
      lockingHistory.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const setPaginationPages = (page) => {
    if (
      renderedPages.findIndex((p) => p === page + 1) === -1 &&
      pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination + 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1),
      );
    } else if (
      renderedPages.findIndex((p) => p === page - 1) === -1 &&
      pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination - 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1),
      );
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
    if (page === lastPageOfPagination) {
      setPaginationPages(page);
    } else if (page === lastPageOfPagination - maxShowingPage + 1) {
      setPaginationPages(page);
    }
    scrollToLockingHistoryContainer();
  };

  const firstPage = () => {
    setCurrentPage(1);
    setLastPageOfPagination(maxShowingPage);
    setRenderedPages(pages.slice(0, maxShowingPage));
    scrollToLockingHistoryContainer();
  };

  const prevPage = () => {
    if (currentPage - 1 !== 0) {
      setCurrentPage(currentPage - 1);
      setPaginationPages(currentPage - 1);
      scrollToLockingHistoryContainer();
    }
  };

  const nextPage = () => {
    if (currentPage + 1 <= pages.length) {
      setCurrentPage(currentPage + 1);
      setPaginationPages(currentPage + 1);
      scrollToLockingHistoryContainer();
    }
  };

  const lastPage = () => {
    setCurrentPage(pages.length);
    if (pages.length > maxShowingPage) {
      setLastPageOfPagination(pages.length);
      setRenderedPages(pages.slice(pages.length - maxShowingPage, pages.length));
    }
    scrollToLockingHistoryContainer();
  };
  const formatDate = (originalDate) => {

    const dateObj = new Date(originalDate);
    const newdateObj = new Date(
      dateObj.getUTCFullYear(),
      dateObj.getUTCMonth(),
      dateObj.getUTCDate(),
      dateObj.getUTCHours(),
      dateObj.getUTCMinutes(),
      dateObj.getUTCSeconds(),
    );
    return newdateObj;
    // return `${dateObj.getUTCDate()}-${
    //   dateObj.getUTCMonth() + 1
    // }-${dateObj.getUTCFullYear()} ${dateObj.getUTCHours()}:
    // ${dateObj.getUTCMinutes()}:${dateObj.getUTCSeconds()}`;
  };
  const formatDateClaim = (originalDate) => {

    const dateObj = new Date(originalDate);
    const newdateObj = new Date(
      dateObj.getUTCFullYear(),
      dateObj.getUTCMonth(),
      dateObj.getUTCDate(),
      dateObj.getUTCHours() +3,
      dateObj.getUTCMinutes(),
      dateObj.getUTCSeconds(),
    );
    return newdateObj;
    // return `${dateObj.getUTCDate()}-${
    //   dateObj.getUTCMonth() + 1
    // }-${dateObj.getUTCFullYear()} ${dateObj.getUTCHours()}:
    // ${dateObj.getUTCMinutes()}:${dateObj.getUTCSeconds()}`;
  };
  useEffect(() => {
    handleAbi();
  }, []);
  function putLocking(uid) {
    const payload = {
      id: uid,
    };
    putLockingRequest(payload);
  }
  const claimToken = async (e, c,selectedItem) => {
    // 0x7FFB0F173B9339134122FDBF22353363Cbe11fe3 dynamic swap contract address
    // TODO : should be added to database
    // e.preventDefault();

    setTxs([]);
    setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
    setLoading({ key: loadingActionTypes.CLAIM_TOKEN_LOADING, isLoading: true });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    const signerAddress = await signer.getAddress();
    const web3 = new Web3(window.ethereum);
    await wallet.controlAndSwitchOrAddNetwork();
    await window.ethereum.enable();
    const mercurx_token = new web3.eth.Contract(
      abiHistory?.[0]?.['MERX_abi'],
      '0x0f1C0c3435e866ec002BbfA1C30cb867453b8920',
    );
    const mercurx_lock = new web3.eth.Contract(
      abiHistory?.[0]?.['MERX_lock_abi_minutes'],
      '0x58c12fe3Fa9e3EF857A3B9E32927F7Fca9C75Abb',
    );
    const etherMiktari = 'valueToBeMultiplied';
    const project_id = 1;
    const project_name = "Mercurx";
    // const token_count = selectedItem.value;
    console.log(selectedItem.reward)
    const rewardString = selectedItem.reward;
    const rewardNumber = parseFloat(rewardString.replace(',', '.')); 
    console.log(rewardNumber)
    const token_count = rewardNumber*10000;
    const action = 'claim';
    const token_address = '0x0f1C0c3435e866ec002BbfA1C30cb867453b8920';
    const transaction_time = new Date();
    const user_public_address = signerAddress;
    try {
      const transaction = await mercurx_lock.methods.claim(e).send({
        from: signerAddress,
        to: '0x58c12fe3Fa9e3EF857A3B9E32927F7Fca9C75Abb',
        data: [web3.eth.abi.encodeFunctionSignature('whitdrawETH()'), 0],
      });
      wallet.getMyBalance('0x0f1C0c3435e866ec002BbfA1C30cb867453b8920');
      const transaction_status = transaction.status;
      const transaction_hash = transaction.transactionHash;
      const payload2 = {
        project_id,
        project_name,
        token_count,
        user_public_address,
        token_address,
        transaction_hash,
        transaction_time,
        transaction_status,
        action,
      };
      console.log(payload2)
      transactionRequest(payload2);
      putLocking(c);
      Swal.fire({
        icon: 'success',
        iconColor: mainColors.primary,
        text: 'Transaction succeed',
        confirmButtonColor: mainColors.primary,
        html:
          '<a href=https://testnet.bscscan.com/tx/' +
          transaction.transactionHash +
          " target='_blank'> Check Detail Transaction !</a>",
      });
      setLoading({ key: loadingActionTypes.CLAIM_TOKEN_LOADING, isLoading: false });
      setLoadingFull({ isLoading: false });

    } catch (err) {
      if (err?.receipt?.transactionHash) {
        Swal.fire({
          icon: 'error',
          iconColor: '#E40039',
          title: 'Transaction is Failed',
          confirmButtonColor: '#E40039',
          // eslint-disable-next-line max-len, no-template-curly-in-string
          html:
            '<a href=https://testnet.bscscan.com/tx/' +
            err.receipt.transactionHash +
            " target='_blank'> Check Detail Transaction !</a>",
        });
        const transaction_status = false;
        const transaction_hash = err.receipt.transactionHash;
        const payload2 = {
          project_id,
          project_name,
          token_count,
          user_public_address,
          token_address,
          transaction_hash,
          transaction_time,
          transaction_status,
          action,
        };
        console.log(payload2)
        transactionRequest(payload2);
        setLoading({ key: loadingActionTypes.CLAIM_TOKEN_LOADING, isLoading: false });
        setLoadingFull({ isLoading: false });
      } else {
        Swal.fire({
          icon: 'warning',
          iconColor: '#E40039',
          confirmButtonColor: '#E40039',
          text: err.message,
        });
        setLoading({ key: loadingActionTypes.CLAIM_TOKEN_LOADING, isLoading: false });
        setLoadingFull({ isLoading: false });
      }
    }
  };
  return (
    <>
      <Table className="locking-history-table" responsive hover borderless>
        <thead>
          <tr className="text-t-body-color text-fs-tag">
            <th>#</th>
            <th>Token</th>
            <th>Value</th>
            <th>Rate</th>
            <th>Reward</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Claim Date</th>
            <th>Status</th>

          </tr>
        </thead>
        {renderedData?.length ? (
          <tbody>
            {renderedData.map((item, index) => {
              const dateObj = formatDate(item.finish_lock_time);
              const date1 = new Date();
              if (currentPage * index < currentPage * maxRowCountPerPage) {
                return (
                  <tr key={index} className="text-t-head-color">
                    <td>{index + 1}</td>
                    <td>{item.symbol}</td>
                    <td>{calculateFormat(item.value)}</td>
                    <td>{(((parseFloat(item.reward) / (parseFloat(item.value)/10000)) - 1) 
                    * 100).toFixed(0)}%</td>
                   <td>{(parseFloat(item.reward) - (parseFloat(item.value) / 10000))
                   .toLocaleString('en-US', { minimumFractionDigits: 1, 
                   maximumFractionDigits: 4 })
                   .replace('.', ',')}</td>
                    <td>{formatDate(item.start_lock_time).toLocaleString()+' GMT+3'}</td>
                    <td>{formatDate(item.finish_lock_time).toLocaleString()+' GMT+3'}</td>
                    <td>{!item.claim_status?
                    (formatDateClaim(item.udate).toLocaleString()+' GMT+3'):("")}</td>
                    <td>
                      {item.claim_status ? (

                        dateObj < date1.getTime() ? (
                          <Button
                            variant="primary"
                            onClick={() => claimToken(item.lock_id, item.id, item)}
                            disabled={isLoading?.[loadingActionTypes.CLAIM_TOKEN_LOADING]}
                          >
                            {isLoading?.[loadingActionTypes.CLAIM_TOKEN_LOADING] && (
                              <Spinner animation="border" size="sm" />
                            )}
                            <span className="ms-1">Claim</span>
                          </Button>


                        ) : (
                          <Button variant="primary" disabled={true}>
                            Locked
                          </Button>
                        )
                      ) : (
                        <Button variant="primary" disabled={true}>
                          Done
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        ) : null}
      </Table>

      {!renderedData?.length && (
        <div className="d-flex text-fs-body-md text-t-body-color justify-content-center">
          No locking history found.
        </div>
      )}

      {pages?.length > 1 ? (
        <Pagination className="d-flex justify-content-center">
          <Pagination.First onClick={() => firstPage()} />
          <Pagination.Prev onClick={() => prevPage()} />
          {renderedPages.map((page, index) => {
            return (
              <Pagination.Item
                key={index}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page}
              </Pagination.Item>
            );
          })}
          <Pagination.Next onClick={() => nextPage()} />
          <Pagination.Last onClick={() => lastPage()} />
        </Pagination>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    lockingHistoryData: state.lockingReducer.lockingHistoryData,
    balance_: state.walletReducer.balance_,
    abiHistory: state.abiReducer.abiHistory,
    isLoading: state.loadingReducer.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    abiHistoryRequest: (payload) => {
      dispatch(abiRequestAction(payload));
    },
    putLockingRequest: (payload) => {
      dispatch(putLockingRequestAction(payload));
    },
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
    setLoading: (payload) => {
      dispatch(setLoadingAction(payload));
    },
    transactionRequest: (creds) => {
      dispatch(transactionRequest(creds));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MercurXLockingHistoryTable);
